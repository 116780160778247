<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerFormModal from '../../../components/DrawerFormModal'
import { getAction } from '@/command/netTool'

export default {
  name: 'anli-relation',
  data() {
    return {
      ...api.command.getState(),
      showModal: false,
      detail: {},
      commodityList: [],
      caseId: this.$route.query.caseId,
    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      api.command.getList.call(this, {
        url: '/farmCaseProductRelation/page',
        current: 1,
        paramsValue: {
          caseId: this.caseId,
        },
      })
    },
    async onMainBodySearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/list?name=${value}`,
      })
      this.commodityList = searchResult.map((el) => {
        return {
          name: el.name,
          value: el.id,
        }
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'productName',
          title: '商品名称',
          align: 'left',
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        {
          dataIndex: 'categories',
          title: '分类',
          align: 'left',
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  this.detail = JSON.parse(JSON.stringify(records))
                  this.showModal = true
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmCaseProductRelation/delBatch`,
                    params: {
                      ids: [records.id],
                    },
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.detail = {
              sort: 50,
            }
            this.showModal = true
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: `/farmCaseProductRelation/delBatch`,
                  params: { ids },
                })
              },
            },
          ],
        },
      ]
    },
    getForm1() {
      return {
        title: '',
        type: 'cardForm',
        form: [
          {
            name: '商品名称',
            labelAlign: 'right',
            type: 'dropDownInput',
            typeData: this.commodityList,
            key: 'productName',
            valueKey: 'productId',
            inputProps: {
              addonBefore: <a-icon type="search" />,
            },
            onInputSearch: this.onMainBodySearch,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '排序号（默认50）',
            type: 'input',
            cols: 24,
            key: 'sort',
            maxLength: 15,
            props: {
              maxLength: 15,
            },
          },
          ,
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },

    onSubmit(data) {
      let obj = {
        ...data,
        caseId: this.caseId,
      }
      api.command[this.detail.id ? 'edit' : 'create']
        .call(this, {
          url: `/farmCaseProductRelation/${this.detail.id ? 'update' : 'save'}`,
          params: {
            ...obj,
          },
          isPost: false,
        })
        .then(() => {
          this.showModal = false
          this.initList()
        })
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="设置"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={[this.getForm1()]}
          form={this.detail}
        ></DrawerFormModal>
      </div>
    )
  },
}
</script>
